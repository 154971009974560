.spy-card {
  background: #1c1c1c;
  border: 2px solid #ff3e3e;
  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
}

.spy-card:hover {
  transform: scale(1.1);
  background-color: #242424;
}

.spy-card-content {
  text-align: center;
}
