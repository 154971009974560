.spy-form-container {
  max-width: 500px;
  margin: 2rem auto;
  padding: 2.5rem;
  background: rgba(20, 20, 20, 0.95);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4),
              inset 0 2px 15px rgba(0, 255, 136, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(0, 255, 136, 0.1);
}

.spy-form-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.spy-logo-animation {
  width: 100px;
  height: 100px;
  margin: 0 auto 1.5rem;
  background: url('/src/assets/spy-logo.png') no-repeat center;
  background-size: contain;
  animation: pulse 2s infinite;
  filter: drop-shadow(0 0 10px rgba(0, 255, 136, 0.3));
}

.spy-form-title {
  color: #00ff88;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-shadow: 0 0 15px rgba(0, 255, 136, 0.5);
  font-weight: 700;
  letter-spacing: 1px;
}

.spy-form-subtitle {
  color: #a0a0a0;
  font-size: 1.1rem;
  line-height: 1.5;
  max-width: 80%;
  margin: 0 auto;
}

.spy-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.form-group {
  display: flex;
  flex-direction: column-reverse;
  gap: 0.75rem;
}

.input-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.icon {
  position: absolute;
  left: 1.2rem;
  color: #00ff88;
  font-size: 1.3rem;
  pointer-events: none;
  opacity: 0.8;
  filter: drop-shadow(0 0 5px rgba(0, 255, 136, 0.3));
}

.spy-input {
  width: 100%;
  padding: 1.2rem 1.2rem 1.2rem 3.5rem;
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(0, 255, 136, 0.2);
  border-radius: 12px;
  color: #fff;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spy-input:hover:not(:focus) {
  border-color: rgba(0, 255, 136, 0.3);
}

.spy-input:focus {
  outline: none;
  border-color: #00ff88;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 20px rgba(0, 255, 136, 0.2),
              inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.spy-input::placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-size: 1rem;
}

label {
  color: #00ff88;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 0.5rem;
  transition: all 0.3s ease;
}

.form-group:focus-within label {
  color: #00ff88;
  text-shadow: 0 0 8px rgba(0, 255, 136, 0.5);
}

.start-game-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1.2rem;
  background: linear-gradient(45deg, #00ff88, #00cc6f);
  border: none;
  border-radius: 12px;
  color: #000;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
}

.start-game-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transition: 0.5s;
}

.start-game-button:hover:not(.disabled)::before {
  left: 100%;
}

.start-game-button:hover:not(.disabled) {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px rgba(0, 255, 136, 0.4);
}

.start-game-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #444;
  transform: none;
  box-shadow: none;
}

.play-icon {
  font-size: 1.3rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    filter: brightness(1);
  }
  50% {
    transform: scale(1.05);
    filter: brightness(1.2);
  }
  100% {
    transform: scale(1);
    filter: brightness(1);
  }
}

/* Responsive design */
@media (max-width: 600px) {
  .spy-form-container {
    margin: 1rem;
    padding: 1.5rem;
    border-radius: 15px;
  }

  .spy-form-title {
    font-size: 2rem;
  }

  .spy-form-subtitle {
    font-size: 1rem;
    max-width: 95%;
  }

  .spy-logo-animation {
    width: 80px;
    height: 80px;
  }

  .spy-input {
    padding: 1rem 1rem 1rem 3rem;
    font-size: 1rem;
  }

  .start-game-button {
    padding: 1rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 500px) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
  }

  .spy-form-container {
    width: 100%;
    max-width: 500px;
    padding: 0 10px;
    margin: 0 auto;
  }

  .spy-form {
    width: 100%;
  }

  .form-group {
    width: 100%;
  }

  .spy-input {
    width: 100%;
  }

  .start-game-button {
    width: 100%;
  }
}

