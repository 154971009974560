.spy-game {
  min-height: 100vh;
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

.spy-reset-button {
  margin: 10px;
  padding: 10px 20px;
  background: #ff3e3e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.spy-reset-button:hover {
  background: #e03232;
}

.spy-game-content {
  margin-top: 20px;
  text-align: center;
}

.spy-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242424;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.spy-card-text {
  font-size: 1.2rem;
  color: #ffffff;
}

.spy-timer-container {
  margin-top: 20px;
}

.spy-title {
  font-size: 2rem;
  color: #ff3e3e;
  text-shadow: 0 0 10px rgba(255, 62, 62, 0.8);
}

.spy-timer {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #f5f5f5;
}

.spy-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.player-card {
  width: 150px;
  height: 200px;
  perspective: 1000px;
  cursor: pointer;
}

.player-card .card-front,
.player-card .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  background-color: #4caf50;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: transform 0.6s;
}

.player-card .card-back {
  transform: rotateY(180deg);
  background-color: #f44336;
}

.player-card.flipped .card-front {
  transform: rotateY(180deg);
}

.player-card.flipped .card-back {
  transform: rotateY(0);
}

.spy-role-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: linear-gradient(145deg, #2a2a2a, #3a3a3a);
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.spy-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #ff4757;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  animation: pulse 2s infinite;
}

.spy-emoji {
  font-size: 1.5rem;
}

.spy-label {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}

.related-words {
  width: 100%;
  text-align: center;
}

.related-words-title {
  color: #ddd;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.related-words-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.related-word-chip {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.4rem 0.8rem;
  border-radius: 15px;
  font-size: 0.9rem;
  color: #fff;
  backdrop-filter: blur(5px);
  transition: transform 0.2s, background 0.2s;
}

.related-word-chip:hover {
  transform: translateY(-2px);
  background: rgba(255, 255, 255, 0.15);
}

.player-prompt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.player-number {
  font-size: 1.5rem;
  color: #fff;
}

.tap-instruction {
  font-size: 0.9rem;
  color: #aaa;
}

.regular-player-word {
  font-size: 2rem;
  color: #fff;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(5px);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
